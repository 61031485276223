import AuthenticationService from "../AuthenticationService";
import BackendService from '../../services/BackendService';
const baseApiUrl = BackendService.getBasepath();
class RestaurantsService
{

    static getRestaurant(id) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/restaurants/${id}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getRestaurantsList(page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/restaurants/list/all?page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static listImagen() {
        const baseApiUrl = "https://api.puerta21.club/api/v1";
        const requestOptions = {
          method: 'GET',
          headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };
        return fetch(`${baseApiUrl}/founder/listImagen`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data && Object.keys(data).length > 0) {
              return data;
            } else {
              return null;
            }
          });
      }

      static getBannerById(id) {
        const requestOptions = {
          method: 'GET',
          headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };
        return fetch(`${baseApiUrl}/founder/banners/details/${id}`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.success == 1) {
              return data.data;
            } else {
              return null;
            }
          });
      }

      static editBanner(id, updatedFields) {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
          body: JSON.stringify(updatedFields)
        };
      
        return fetch(`${baseApiUrl}/founder/banners/edit/${id}`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.success == 1) {
              return data;
            } else {
              return null;
            }
          });
      }

      static statusBanner(id, updatedFields) {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
          body: JSON.stringify(updatedFields)
        };
      
        return fetch(`${baseApiUrl}/founder/banners/status/${id}`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.success === 1) {
              return data;
            } else {
              return null;
            }
          });
      }

      static createdBanner(updatedFields, image) {
        const formData = new FormData();
      
        // Agregar los campos de texto al FormData
        for (const [key, value] of Object.entries(updatedFields)) {
          formData.append(key, value);
        }
      
        // Agregar la imagen al FormData
        formData.append('image', image);
      
        const requestOptions = {
          method: 'POST',
          headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
          body: formData
        };
      
        return fetch(`${baseApiUrl}/founder/banners/created`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.success == 1) {
              return data;
            } else {
              return { success: 0, message: data.message };
            }
          });
      }
      static uploadCover = (file) => {
        const formData = new FormData();
        formData.append('file', file);
      
        return fetch('/founder/banners/cover', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': `Bearer ${AuthenticationService.curentAccessToken}`
          }
        })
          .then(response => response.json())
          .then(data => {
            if (data.success === 1) {
              return data.data;
            } else {
              return null;
            }
          });
      };
    static getRestaurantsSearch(name, page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: {  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/restaurants/list/search?name=${name}&page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static postNewRestaurant(model) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    name : model.name,
                    owner_name : model.ownerName,
                    email : model.email,
                    address : model.address,
                    latitude : model.latitude,
                    longitude : model.longitude,
                    zone_eid : model.zone,
                    kitchens : model.kitchens,
                    cost_eid : model.cost,
                    extra_services : model.extraServices
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putDescription(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    restaurant_eid : model.id,
                    description : model.description,
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/information`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putAddress(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    restaurant_eid : model.id,
                    address : model.address,
                    latitude : model.latitude,
                    longitude : model.longitude
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/address`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putPhone(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    restaurant_eid : model.id,
                    phone : model.phone
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/information`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putChef(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    restaurant_eid : model.id,
                    cheff_name : model.chef
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/information`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putKitchen(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    restaurant_eid : model.id,
                    kitchen_eid : model.idKitchen,
                    status : model.status
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/kitchen`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putCost(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    restaurant_eid : model.id,
                    cost_eid : model.costId
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/cost`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putExtraService(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    restaurant_eid : model.id,
                    extra_service_eid : model.serviceId,
                    status : model.status
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/extra-service`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putMenu(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    menu_url : model.url,
                    restaurant_eid : model.id
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/menu`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putCapacity(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    restaurant_eid : model.id,
                    capacity : model.capacity
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/capacity`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putServiceDay(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify({
                restaurant_eid : model.id,
                service_days : model.days
            })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/service-day`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

    static putRestaurantStatus(restaurantId, status) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                { 
                    restaurant_eid : restaurantId, 
                    status : status 
                })
        };

        return fetch(`${baseApiUrl}/founder/restaurants/active`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }

}
export default RestaurantsService