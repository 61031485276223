import React, { useEffect, useState } from 'react'
import {useParams, Link } from 'react-router-dom'
import CheckBoxComponent from '../../components/CheckBoxComponent'
import { useMediaQuery } from 'react-responsive'
import PriceComponent from '../../components/PriceComponent'
import ModalComponent, { ModalFunctions } from '../../components/ModalComponent'
import TextBoxComponent from '../../components/TextboxComponent'
import RestaurantsService from '../../services/founder/RestaurantsServices'
import CatalogsService from '../../services/app/CatalogsService'
import Select from 'react-select';
import { Rating } from 'react-simple-star-rating'
import { RatingView } from 'react-simple-star-rating'
import FieldEditable from '../../components/FieldEditable'
import OffersService from '../../services/founder/OffersService'

import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import DatePicker from "react-multi-date-picker"
import gregorianes from "react-date-object/locales/gregorian_es";
import { v4 as uuid } from 'uuid';
import {
    KeyboardTimePicker,
    KeyboardDatePicker,

} from '@material-ui/pickers';
import moment from 'moment';
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
  } from 'react-places-autocomplete';
import { toast } from 'react-toastify'
import IsSpecialCharacters from '../../services/UltiService'
import IsSpecialCharactersNotDot from '../../services/ValidateCaracters'
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {

    TextField,
 
} from '@material-ui/core';

const OfferDetails = () => {
    
    const model ={
      'id': 0,
      'title': '',
    'description':'',
    }
    const [values, setValues, offerTitle, setOfferTitle] = React.useState([])
    const [startDate, setStartDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'));

    const [descriptionValidation,setDescriptionValidation]= React.useState({});
    const [directionValidation,setDirectionValidation]= React.useState(true);
    const [addresValidation,setAddresValidation]= React.useState(true);
    const [phoneValidation,setPhoneValidation]= React.useState({});
    const [chefValidation,setChefValidation]= React.useState({});
    const [selectedRestaurants, setSelectedRestaurants] = React.useState([]);
    const [repeat, setRepeat] = React.useState(false);

    const [isCalendarEndDateOpen, setIsCalendarEndDateOpen] = React.useState(false);
    const handleDateStartDateChange = (date) => {
        setStartDate(date);
        const target = format(new Date(date), 'yyyy-MM-dd');
        updateFields('startDate', target);

        // if (dateRegex.test(date)) {
        // }    //validatedates(target, fields.startTime, fields.endDate, fields.endTime);
    };
  
    const [hasOffers, setHasOffers] = React.useState(false);
    const { id } = useParams();
    const [restaurantId] = React.useState(useParams());
    const [restaurantInfo, setRestaurantInfo]= React.useState(model);
    const [cost,setCost]= React.useState("");
    //restaurantname
    const [restaurantname,setRestaurantname]= React.useState("");
    const [costsCatalog,setCostsCatalog] = React.useState([]);
    const [extraServicesCatalog,setExtraServicesCatalog] = React.useState([]);
    const [kitchenCatalog,setKitchenCatalog] = React.useState([]);
    const [options, setOptions] = React.useState([]);
  
 
    const [offers, setOffers] = useState([]);
    const [latitude,setLatitude]= React.useState(0);
    const [longitude,setLongitude]= React.useState(0);
    const [direction,setDirection]= React.useState("");
      
    const [cancel,setCancel] = React.useState("");
    const [address, setAddress] = React.useState('');

    const [kitchensOrigin,setKitchensOrigin] = React.useState("")
    const [rating,setRating] = React.useState(0);

    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    const updateFields = (field, value) => {
        const newFields = {
            ...fields,
        };
        newFields[field] = value;
        setFields(newFields);
    };
    
    const [fields, setFields] = React.useState({
        title: '',
        description: '',
        posicion: '',
        imagen: '',
        enlace:'',
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        type: 'oferta',
    });

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        uploadImage(formData)
          .then((imageUrl) => {
            // Actualiza el campo de imagen con la dirección de la imagen subida
            updateFields('imagen', imageUrl);
          })
          .catch((error) => {
            console.error('Error al subir la imagen:', error);
          });
      };

      const uploadImage = (formData) => {
        return fetch('URL_DE_TU_ENDPOINT_DE_SUBIDA_DE_IMAGEN', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            // Aquí puedes extraer la dirección de la imagen subida del objeto de respuesta
            const imageUrl = data.imageUrl;
      
            return imageUrl;
          });
      };

    function handleDescriptionChange(e){
        restaurantInfo.description.description = e.target.value;
        if(e.target.value == "" || e.target.value.includes('  ') || IsSpecialCharactersNotDot(e.target.value)){
            setDescriptionValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else if(e.target.value.length > 250){
            setDescriptionValidation({hasValidate:true,hasError:true,error:'Máximo 250 caracteres.'});
        }
        else if (regex.test(e.target.value)) {
            setDescriptionValidation({hasValidate:true,hasError:true,error:'Formato incorrecto.'});
        }
        else{
            setDescriptionValidation({hasValidate:true,hasError:false})
        }
    }
    

    function addNewoffer() {
        if (fields.title === '') {
            error('El título no puede estar vacío');
   
            return;
          }
          if (fields.description === '') {
            error('La descripción no puede estar vacía');
            return;
          }
          if (fields.posicion === '') {
            error('Este campo no puede estar vacío');
            return;
          }
          if (fields.imagen === '') {
            error('Este campo no puede estar vacío');
            return;
          }
          if (fields.enlace === '') {
            error('Este campo no puede estar vacío');
            return;
          }
          
          if (fields.type === '') {
            error('El tipo no puede estar vacío');
        
            return;
          }
 


          const body = {};
          body.title = fields.title;
          body.description = fields.description;
          body.posicion = fields.posicion;
          body.imagen = fields.imagen;
          body.enlace = fields.enlace;
          body.type = fields.type;
        
          console.log(body);


          RestaurantsService.editBanner(id,body).then((r) =>{
            if(r != null && r.success == 1){
                notify(r.message)
                setTimeout(() => {
                    document.location = '/Banners';
                }, 1500);
            }
            else{
                error(r.message)
            }
        });
   

    }
    useEffect(() => {
        RestaurantsService.listImagen()
          .then(data => {
            console.log(data); // Verifica los datos recibidos en la consola
            console.log(id);
            if (data && data.length > 0) {
              const matchingImage = data.find(image => image.id === parseInt(id));
              if (matchingImage) {
                setFields(prevFields => ({
                  ...prevFields,
                  title: matchingImage.titulo,
                  description: matchingImage.descripcion,
                  posicion: matchingImage.posicion,
                  imagen: matchingImage.imagen,
                  enlace: matchingImage.enlace,
                }));
              }
            }
          })
          .catch(error => {
            console.error('Error al obtener los datos de imagen:', error);
          });
      }, [id]);
    const renderView = () => (
        <>
            <div className="w-100 mx-2">
                <div className="RestaurantOffer-form-container mr-2">
                    <div style={{ color: 'black', fontFamily: 'roboto', fontSize: '16px' }}>
                        <span>
                            <b>Detalles del banner</b>
                            <br />
                           
                        </span>
                    </div>
                    <div className="RestaurantOffer__form">
                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="title"
                                label="Título"
                                value={fields.title}
                                onChange={(e) => updateFields('title', e.target.value)}
                              
                            />
                        </div>
                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="description"
                                label="Descripción"
                                onChange={(e) => updateFields('description', e.target.value)}
                                value={fields.description}
                            />
                        </div>

                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="posicion"
                                label="Posición"
                                onChange={(e) => updateFields('posicion', e.target.value)}
                                value={fields.posicion}
                            />
                        </div>
                    
                        <div className="w100 mb-1">
                        <label htmlFor="imagen" style={{
                            backgroundColor: 'black',
                            color: 'white',
                            border: 'none',
                            padding: '10px 20px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            fontSize: '16px'
                            }}>
                            Cargar banner
                            </label>
                            <input
                            type="file"
                            className="w100"
                            id="imagen"
                            label="Imagen"
                            onChange={(e) => updateFields('imagen', e.target.files[0])}
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                zIndex: -1
                            }}
                            />
                          {fields.imagen && (
                                <div>
                                <p>Archivo seleccionado:</p>
                               
                                <p>{fields.imagen.name}</p>
                              </div>
                              )}
                        </div>
                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="enlace"
                                label="Posición"
                                onChange={(e) => updateFields('enlace', e.target.value)}
                                value={fields.enlace}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );


    function getCheckedKitchen(kitchenId) {
        let data = restaurantInfo.kitchens.find(x => x.encrypt_id === kitchenId);
        console.log(data)
        if(data != undefined){
            return data.status == 1 ? true : false;
        }else{
            return false;
        }
    }

    function getExtraCatalog(){
        CatalogsService.getExtraService().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setExtraServicesCatalog(r);
                }
            }
        });
    }

    function getCostsCatalog(){
        CatalogsService.getCost().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setCostsCatalog(r);
                }
            }
        });
    }

    function getKitchensCatalog(){
        CatalogsService.getKitchen().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setKitchenCatalog(r.map((k) => {return {value: k.encrypt_id, label: k.name}}));
                }
            }
        });
    }

    function getKitchenOrigin(kitchens){
        let temp = "";
        kitchens.filter(x => x.status === 1).map((k) => { temp += k.name+',' });
        setKitchensOrigin(temp)
    }

    function getRestaurantInfo(){
        RestaurantsService.getRestaurant(restaurantId.id).then((r) => {
            if(r != null)
            {
                setRestaurantInfo(r);
                getKitchenOrigin(r.kitchens);
                setAddress(r.address.address);
                setRating(r.cost.name.length);
            }
            else
            {

            }
        });
    }

    function getCheckedExS(check, data){
        if(data.length != 0)
        {
            let extra = data.find(x => x.name == check);
            if(extra != undefined){
                return extra.status === 1 ? true : false;
            }
            else
            {
                return false
            }
        }
        else
        {
            return false
        }
    }


    function putCost(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(cost != "" && cost != restaurantInfo.cost.id){
            let model = {};
            model.id = restaurantId.id;
            model.costId = cost;
            RestaurantsService.putCost(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                ModalFunctions.HideModal('modalPrice'); 
                getRestaurantInfo();
            });
        }

    }

    function putKitchen(data){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        let model = {};
        model.id = restaurantId.id;
        model.idKitchen = data.idKitchen;
        model.status = data.status;
        RestaurantsService.putKitchen(model).then((r) => {
            r.success === 1 ? 
            notify()
            :
            error()
            getRestaurantInfo();
        });
    }

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      function error(message) {
        toast.error(message != undefined ? message : 'No se pudo actualizar', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }
     
    const handleSelect = address => {
        setAddress(address);
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
              setLatitude(latLng.lat);
              setLongitude(latLng.lng);
              setDirection(address);
              setDirectionValidation(true);
              setAddresValidation(true);
            })
          .catch(error => console.error('Error', error));
      };
    
      const handleRating = (rate) => {
        setRating(rate)
      }

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    React.useEffect(() => {
        const getOffer = async (id) => {
            try {
              const offer = await RestaurantsService.getBannerById(id);
              console.log(offer);
    

              if (offer) {
                setFields(
                    {title: offer.title,
                    description: offer.description,
                    startDate: offer.startDate,
                    endDate: offer.endDate,
                    type: offer.type}
                );
                setStartDate(offer.start_date);
                setEndDate(offer.end_date);
                setRepeat(offer.repeats);
              
                setHasOffers(offer.hasOffers);

                setRestaurantname(offer.restaurant_name);
  
          const vdates = offer.dates;
          const parsedjson = JSON.parse(vdates);
          const datesarray = [];
          for (let i = 0; i < parsedjson.length; i += 1) {
            const date = new Date(parsedjson[i]);
            // fix problem with date format reducing one day
            date.setDate(date.getDate() + 1);
            datesarray.push(date);
          }
            setValues(datesarray);
  
              } else {
                console.log("No se pudo encontrar la banner");
              }
            } catch (error) {
              console.error("Error al obtener la banner:", error);
            }
          };
            getOffer(id);
      }, [id]);


    const desktop = () =>(
        <article>
        <div style={{ display: "flex", alignItems: "center", padding: "29px 40px" }}>
            <label style={{
                width: "111px",
                height: "25px",
                fontFamily: "Commuters Sans SemiBold",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.25,
                letterSpacing: "normal",
                textAlign: "left",
                "flex-grow": "1",
                color: "black"
            }}>
                EDITAR BANNER
            </label>
        </div>
        <div style={{
            borderBottom: "2px solid #c8c8c8",
            height: "0px",
            textAlign: "left",
            padding: "0 24px"
        }}>
        </div>
        <div style={{ display: "flex", padding: "29px 36px 29px 40px", height: 'calc(100% - 150px)', overflow: 'auto' }}>
            <div style={{ display: "flex", width: '30%', justifyContent: 'space-between' }}>
                <div style={{ textAlign: "left" }}>
                    <p style={{
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        color: 'black',
                        letterSpacing: '0.5px',
                        width: '80%',
                        paddingTop: '40px'
                    }}>
                        Editar los datos de banner.
                    </p>
                    <p>Banner Actual</p>
                    <img
                        src={`https://api.puerta21.club/storage/app/public/${fields.imagen}`}
                        alt=""
                        style={{ maxWidth: '400px', marginTop: '10px' }}
                    />
                </div>
                <div style={{
                    width: "0px",
                    height: "630px",
                    margin: "56px 0px 70px",
                    opacity: "0.15",
                    border: "solid 0.5px #000001",
                    paddingLeft: "0px",
                    paddingRight: "0px"
                }}>
                </div>
            </div>
            <div style={{ display: 'flex', width: '70%' }}>
                <form style={{
                    textAlign: "left",
                    paddingLeft: "40px",
                    width: '100%'
                }}>
                    <div className="W100">
                        {renderView()}
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end" style={{ paddingTop: '20px' }}>
                        <Link to="/" className="btn btn-lg mt-5" style={{ width: '162px', boxShadow: 'none', fontFamily: 'Commuters Sans SemiBold' }}>
                            CANCELAR
                        </Link>
                        <button className="btn btn-primary btn-lg mt-5" type="button" style={{ width: '162px' }} onClick={() => { addNewoffer() }}>
                            GUARDAR CAMBIOS
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </article>
    );
        
    const movile = () =>(
        
         <article>
        <div style={{ display: "flex", alignItems: "center", padding: "29px 40px" }}>
            <label style={{
                width: "111px",
                height: "25px",
                fontFamily: "Commuters Sans SemiBold",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.25,
                letterSpacing: "normal",
                textAlign: "left",
                "flex-grow": "1",
                color: "black"
            }}>
                EDITAR BANNER
            </label>
        </div>
        <div style={{
            borderBottom: "2px solid #c8c8c8",
            height: "0px",
            textAlign: "left",
            padding: "0 24px"
        }}>
        </div>
        <div style={{ display: "flex", padding: "29px 36px 29px 40px", height: 'calc(100% - 150px)', overflow: 'auto' }}>
            <div style={{ display: "flex", width: '30%', justifyContent: 'space-between' }}>
                <div style={{ textAlign: "left" }}>
                    <p style={{
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        color: 'black',
                        letterSpacing: '0.5px',
                        width: '80%',
                        paddingTop: '40px'
                    }}>
                        Editar los datos del Banner
                    </p>
                </div>
                <div style={{
                    width: "0px",
                    height: "630px",
                    margin: "56px 0px 70px",
                    opacity: "0.15",
                    border: "solid 0.5px #000001",
                    paddingLeft: "0px",
                    paddingRight: "0px"
                }}>
                </div>
            </div>
            <div style={{ display: 'flex', width: '70%' }}>
                <form style={{
                    textAlign: "left",
                    paddingLeft: "40px",
                    width: '100%'
                }}>
                    <div className="W100">
                        {renderView()}
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end" style={{ paddingTop: '20px' }}>
                        <Link to="/" className="btn btn-lg mt-5" style={{ width: '162px', boxShadow: 'none', fontFamily: 'Commuters Sans SemiBold' }}>
                            CANCELAR
                        </Link>
                        <button className="btn btn-primary btn-lg mt-5" type="button" style={{ width: '162px' }} onClick={() => { addNewoffer() }}>
                            GUARDAR CAMBIOS
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </article>
    );
    

    return (
        isDesktopOrLaptop ? desktop() : movile()
    )
}

export default OfferDetails
